<script>
import { getErrorMessageByErrorCode } from "@/shared/util/helpers";
import ActivityService from "../../api/services/activity.service";
import UploadService from "../../api/services/upload.service";
import cookie from "vue-cookies";
import UserService from "../../api/services/user.service.js";
import ResponseStatus from "../../shared/enums/responseStatus";

const ENABLE_DEBUG_LOGS = false;

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileInputObject: Object,
    selectedColumns: Array,
    creditAmountReturn: Object,
    loggedInUser: Object,
    enableProgressBar: Boolean
  },
  data() {
    return {
      beforeUpload: true,
      uploadDatafileSuccess: false,
      datafileId: null,
      isUploadDatafileError: false,
      uploadDatafileErrorMsg: null,
      uploadProgress: 0,
      keepS3File: false,
      skipFeedbackFile: false,
      datafileKey: null,
      showProgressBar: this.enableProgressBar,
      socket: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    if (this.enableProgressBar) {
      this.beforeUpload = false;
      const { datafileKey } = this.datafileInputObject;

      this.datafileKey = datafileKey;
      this.initializeWebSocket();
    }
  },
  methods: {
    initializeWebSocket() {
      // Create a new websocket
      try {
        const socket = new WebSocket(
          process.env.VUE_APP_WEB_SOCKET_SERVER_BE_PROGRESS
        );
        this.socket = socket;
        socket.onopen = () => {
          console.log("socket open");
          const jwtToken = cookie.get("accessToken");
          socket.send(
            jwtToken +
              ";" +
              "TRACK_PROGRESS" +
              ";" +
              this.datafileKey +
              ";" +
              "START"
          );
        };
        socket.onmessage = (event) => {
          const data = event.data;
          const socketData = String(data).split(";");
          const datafileKey = String(socketData[1]);
          if (datafileKey === this.datafileKey) {
            if (socketData[0] === "PROGRESS_UPDATE") {
              const PROGRESS = Number(socketData[2]);
              if (ENABLE_DEBUG_LOGS)
                console.log(
                  `OES progress update for ${datafileKey}: ${PROGRESS}%`
                );
              this.uploadProgress = Number(PROGRESS);
            } else if (socketData[0] === "PROGRESS_FINISHED") {
              const datafileId = socketData[3];
              if (ENABLE_DEBUG_LOGS)
                console.log("Upload process finished. Closing socket now.");
              socket.close();
              this.showProgressBar = false;
              this.uploadDatafileSuccess = true;
              this.datafileId = datafileId;
            } else if (socketData[0] === "UPLOAD_ERROR") {
              if (ENABLE_DEBUG_LOGS)
                console.log("Received UPLOAD_ERROR from socket");
              const errorCode = socketData[2];
              this.showProgressBar = false;
              this.isUploadDatafileError = true;
              this.uploadDatafileErrorMsg =
                getErrorMessageByErrorCode(errorCode);
            }
          }
        };

        socket.onclose = (e) => {
          console.log("Socket closed", e);
          // TODO: show error
        };
      } catch (e) {
        if (ENABLE_DEBUG_LOGS) {
          console.log("Failed to create websocket for progress updates");
          console.log(e);
        }
      }
    },
    async uploadDataSet() {
      try {
        const selectedColumnsJString = JSON.stringify(this.selectedColumns);
        const creditAmountJString = JSON.stringify(this.creditAmountReturn);
        const datafileInputObjectJString = JSON.stringify(
          this.datafileInputObject
        );
        await UserService.changeDatafileUploadingProgressStatus({
          datafileInputObject: datafileInputObjectJString,
          selectedColumns: selectedColumnsJString,
          creditAmount: creditAmountJString
        });
      } catch (e) {
        if (ENABLE_DEBUG_LOGS)
          console.log(
            "Failed to store values for upload progress background to database"
          );
      }
      // 1. animationVariable start
      // 2. get datafileInputObject from localStorage and match it to the typedef
      const {
        dataRecordIdColumn,
        headerRow,
        dataRecordStartRow,
        sheetName,
        dataPointMetadata,
        groupSelectors,
        missingValues
      } = this.datafileInputObject.metadata;

      const metadataRequestObject = {
        dataRecordIdColumn,
        headerRow,
        dataRecordStartRow,
        sheetName,
        dataPointMetadata,
        groupSelectors,
        missingValues
      };

      const {
        title,
        datafileKey,
        numberOfQuestions,
        eligibleCertificateLevel
      } = this.datafileInputObject;

      this.datafileKey = datafileKey;

      // //detect if OES is avaialble before upload
      // let hasOES = false;
      // for (const datapoint of dataPointMetadata) {
      //   for (const key in datapoint.algorithms) {
      //     if (
      //       key === ALGORITHM_TYPES.OES &&
      //       datapoint.algorithms[key] === true
      //     ) {
      //       hasOES = true;
      //       continue;
      //     }
      //   }
      // }

      this.initializeWebSocket();

      const datafileRequestObject = {
        title,
        datafileKey,
        numberOfQuestions,
        metadata: metadataRequestObject,
        keepS3File: this.keepS3File,
        isSkipFeedbackFile: this.skipFeedbackFile
      };

      // 3. pass getUserInfoError to addDataset Mutation
      this.showProgressBar = true;
      this.beforeUpload = false;

      try {
        const res = await UploadService.uploadDatafile(
          datafileRequestObject,
          eligibleCertificateLevel
        );
        const status = res?.data?.data?.addDatafile?.status;
        if (status !== ResponseStatus.SUCCESS) {
          this.showProgressBar = false;
          this.isUploadDatafileError = true;
          this.uploadDatafileErrorMsg = getErrorMessageByErrorCode(status);
          this.socket.close();
          console.error(res?.data?.data?.addDatafile?.message);
        }
      } catch (e) {
        if (ENABLE_DEBUG_LOGS) console.log("Upload error: ", e);
        this.showProgressBar = false;
        this.isUploadDatafileError = true;
        this.uploadDatafileErrorMsg = getErrorMessageByErrorCode(e.code);
        this.socket.close();
        console.error(e);
      }
    },
    async trackActivity(action) {
      await ActivityService.createActivity({
        owner: this.loggedInUser.id,
        companyId: this.loggedInUser.company.id,
        action
      });
    },
    goToDatasets() {
      // 1. remove all items from locastorage
      // 2. redirect user to datafile result page
      window.localStorage.removeItem("selectedColumns");
      window.localStorage.removeItem("datafileMetaDataArray");
      window.localStorage.removeItem("workingMetaDataArray");
      window.localStorage.removeItem("datafileInputObject");
      this.trackActivity("UPLOAD");
      this.$router.push({
        path: "/datafile-result",
        query: { datafileId: this.datafileId, activateRawTab: true }
      });
    },
    async clickMakeUploadBackground() {
      let notificationProm = UserService.setSendNotification(true, "upload");
      let localStorageProm = UserService.setClearLocalStorage(true);
      await Promise.all([notificationProm, localStorageProm]);
      this.$router.push("/");
    }
  }
};
</script>

<template>
  <!-- Upload Confirmation Modal -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="row mt-2">
      <div class="col-lg-12 mb-3 text-center">
        <h5>{{ $t("uploadProcessStep3.uploadConfirmationPopup.title") }}</h5>
      </div>
      <div class="col-lg-12">
        <div class="mt-3">
          <!-- before Upload -->
          <p v-if="beforeUpload" class="text-center">
            {{ $t("uploadProcessStep3.uploadConfirmationPopup.description") }}
          </p>

          <!-- Uploading -->
          <div v-if="showProgressBar" class="d-flex justify-content-center">
            <h5>
              {{ $t("uploadProcessStep3.uploadConfirmationPopup.calculating") }}
            </h5>
          </div>

          <div v-if="showProgressBar" class="mb-3">
            <b-progress
              class="mt-4"
              :max="100"
              show-progress
              animated
              height="30px"
              style="font-size: 15px"
            >
              <b-progress-bar
                :value="uploadProgress"
                show-value
              ></b-progress-bar>
            </b-progress>
          </div>

          <div
            v-if="showProgressBar"
            class="d-flex justify-content-center mb-3"
          >
            <p style="text-align: center">
              {{
                $t(
                  "uploadProcessStep3.uploadConfirmationPopup.bePatience.title"
                )
              }}
              <br />
              {{
                $t(
                  "uploadProcessStep3.uploadConfirmationPopup.bePatience.text1"
                )
              }}
              <br />
              <br />
              {{
                $t(
                  "uploadProcessStep3.uploadConfirmationPopup.bePatience.text2"
                )
              }}
            </p>
          </div>

          <!-- Uploaded -->
          <div
            v-if="uploadDatafileSuccess"
            class="d-flex justify-content-center mb-3"
          >
            <i class="bx bx-check-circle bx-tada text-success display-1"></i>
          </div>
          <div
            v-if="uploadDatafileSuccess"
            class="d-flex justify-content-center"
          >
            <h5>
              {{
                $t("uploadProcessStep3.uploadConfirmationPopup.success.title")
              }}
            </h5>
          </div>
          <div
            v-if="uploadDatafileSuccess"
            class="d-flex justify-content-center mb-3"
            style="text-align: center"
          >
            <p>
              {{
                $t("uploadProcessStep3.uploadConfirmationPopup.success.text")
              }}
            </p>
          </div>

          <!-- Uploaded Error -->
          <div
            v-if="isUploadDatafileError"
            class="d-flex justify-content-center mb-3"
          >
            <i class="bx bx-x-circle bx-tada text-danger display-1"></i>
          </div>
          <div
            v-if="isUploadDatafileError"
            class="d-flex justify-content-center"
          >
            <h5>
              {{ $t("uploadProcessStep3.uploadConfirmationPopup.error.title") }}
            </h5>
          </div>
          <div
            v-if="isUploadDatafileError"
            class="d-flex justify-content-center mb-3"
          >
            <p style="text-align: center">
              {{ $t("uploadProcessStep3.uploadConfirmationPopup.error.text") }}
              <a
                href="mailto:support@redem.io?subject=Probleme beim Hochladen des Datenfiles"
                >support@redem.io</a
              >.
            </p>
          </div>
          <b-alert v-model="isUploadDatafileError" class="mt-3" variant="danger"
            >{{ uploadDatafileErrorMsg }}
          </b-alert>
        </div>
      </div>

      <div
        class="col-md-12 text-center"
        v-if="beforeUpload && loggedInUser.accessLevel === 'SUPER_ADMIN'"
      >
        <b-form-checkbox
          v-model="keepS3File"
          name="check-button"
          switch
          class="mt-2"
        >
          Keep XLSX file in S3
        </b-form-checkbox>
      </div>

      <div
        class="col-md-12 text-center"
        v-if="beforeUpload && loggedInUser.accessLevel === 'SUPER_ADMIN'"
      >
        <b-form-checkbox
          v-model="skipFeedbackFile"
          name="check-button"
          switch
          class="mt-2"
        >
          Skip Feedback File (Reading & Writing)
        </b-form-checkbox>
      </div>

      <div class="p-4 col-md-12 text-center">
        <b-button
          v-if="beforeUpload"
          variant="success"
          @click="uploadDataSet()"
          class="mr-2"
          >{{
            $t("uploadProcessStep3.uploadConfirmationPopup.confirmButton")
          }}</b-button
        >
        <b-button
          v-if="beforeUpload || isUploadDatafileError"
          variant="light"
          @click="showModal = false"
          >{{
            $t("uploadProcessStep3.uploadConfirmationPopup.cancelButton")
          }}</b-button
        >
        <b-button
          v-if="uploadDatafileSuccess"
          variant="warning"
          @click="goToDatasets()"
          >{{
            $t(
              "uploadProcessStep3.uploadConfirmationPopup.continueToResultButton"
            )
          }}</b-button
        >
        <b-button
          v-if="showProgressBar"
          variant="warning"
          @click="clickMakeUploadBackground()"
          >{{
            $t("uploadProcessStep3.uploadConfirmationPopup.backToDashboard")
          }}</b-button
        >
      </div>
    </div>
  </b-modal>
</template>
