var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("uploadProcessStep3.pageTitle"),
          items: _vm.items,
        },
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-5" },
          [
            _c("b-card", { attrs: { "no-body": "" } }, [
              _c("div", { staticClass: "row p-3" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(this.datafileInputObject?.title)),
                  ]),
                  _c("hr"),
                ]),
                _c("div", { staticClass: "col-md-12 mt-3" }, [
                  this.datafileInputObject?.eligibleCertificateLevel ||
                  this.datafileInputObject?.certificateLevel
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-7" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep3.metaDataCard.certificateLevel"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("div", { staticClass: "badge badge-gold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.datafileInputObject
                                    ?.eligibleCertificateLevel ||
                                    this.datafileInputObject?.certificateLevel
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-7" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.metaDataCard.numberOfDataRecords"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            this.datafileInputObject?.numberOfRespondents
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-7" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.metaDataCard.numberOfQualityScores"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _vm._v(" " + _vm._s(this.numberOfQualityScores) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-7" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.metaDataCard.numberOfDatapoints"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _vm._v(" " + _vm._s(this.numberOfDatapoints) + " "),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            this.datafileInputObject?.amountOfCredits
              ? _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("h5", [
                          _c("i", {
                            staticClass:
                              "bx bx-money h4 text-warning bx-tada mr-1",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("uploadProcessStep3.pricingCard.title")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 text-right" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://help.redem.io/docs/data-import/redem-credits/",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "uploadProcessStep3.pricingCard.learnMore"
                                )
                              ) + " "
                            ),
                            _c("i", {
                              staticClass: "bx bx-link-external ml-1",
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.pricingCard.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("uploadProcessStep3.pricingCard.note")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("uploadProcessStep3.pricingCard.point1")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("uploadProcessStep3.pricingCard.point2")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "table-responsive mt-4" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "uploadProcessStep3.pricingCard.table.header.description"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("th", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "uploadProcessStep3.pricingCard.table.header.amount"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "uploadProcessStep3.pricingCard.table.header.credits"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          this.creditAmountReturn.OES.credits
                            ? _c("tr", [
                                _c("td", [_vm._v("OES")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.OES
                                          .numberOfDataPoints
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.OES.credits.toLocaleString(
                                          _vm.localeFormat,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          this.creditAmountReturn.TS.credits
                            ? _c("tr", [
                                _c("td", [_vm._v("TS")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.TS
                                          .numberOfDataPoints
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.TS.credits.toLocaleString(
                                          _vm.localeFormat,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          this.creditAmountReturn.IBS.credits
                            ? _c("tr", [
                                _c("td", [_vm._v("GQS Items")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.IBS
                                          .numberOfDataPoints
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.creditAmountReturn.IBS.credits.toLocaleString(
                                            _vm.localeFormat,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          this.creditAmountReturn.PQ.credits
                            ? _c("tr", [
                                _c("td", [_vm._v("Projective Question")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.PQ
                                          .numberOfDataPoints
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.PQ.credits.toLocaleString(
                                          _vm.localeFormat,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          this.creditAmountReturn.difference
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.difference > 0
                                          ? _vm.$t(
                                              "uploadProcessStep3.pricingCard.table.differencePlus.label"
                                            )
                                          : _vm.$t(
                                              "uploadProcessStep3.pricingCard.table.differenceMinus.label"
                                            )
                                      ) +
                                      " "
                                  ),
                                  _c("span", [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover",
                                          value:
                                            _vm.propoverMethodCreditDifference,
                                          expression:
                                            "propoverMethodCreditDifference",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass:
                                        "ml-2 bx bxs-info-circle text-info",
                                      staticStyle: { cursor: "pointer" },
                                    }),
                                  ]),
                                ]),
                                _c("td"),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.creditAmountReturn.difference.toLocaleString(
                                          _vm.localeFormat,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-left",
                                attrs: { colspan: "2" },
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.pricingCard.table.total.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("td", { staticClass: "text-right" }, [
                              _c("h5", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.datafileInputObject?.amountOfCredits.toLocaleString(
                                        _vm.localeFormat,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-left",
                                attrs: { colspan: "2" },
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.pricingCard.table.perRespTotal"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("td", { staticClass: "text-right" }, [
                              _c("h5", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        this.datafileInputObject
                                          ?.amountOfCredits /
                                        this.datafileInputObject
                                          ?.numberOfRespondents
                                      ).toLocaleString(_vm.localeFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            this.isCreditsLoading
              ? _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-center p-5" },
                    [
                      _c("b-spinner", {
                        staticClass: "m-2",
                        staticStyle: { width: "5rem", height: "5rem" },
                        attrs: { variant: "mid", role: "status" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-7" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h5", [
                  _c("i", {
                    staticClass: "bx bx-detail h4 text-info bx-tada mr-1",
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("uploadProcessStep3.summaryCard.title")) +
                      " "
                  ),
                ]),
                _vm._l(this.selectedColumns, function (column, columnIndex) {
                  return _c(
                    "div",
                    { key: columnIndex, staticClass: "ml-2 mr-2 mt-5" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-badge",
                            {
                              staticStyle: { "font-size": "12px" },
                              attrs: { variant: "primary" },
                            },
                            [
                              column.columnType === "PROJECTIVE_QUESTION"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnTypes.projectiveQuestion"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              column.columnType === "RESPONDENT_ID"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnTypes.dataRecordID"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              column.columnType === "TIMESTAMP"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnTypes.timeStamp"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              column.columnType === "OPEN_ENDED"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnTypes.openEnded"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              column.columnType === "ITEMBATTERY"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnTypes.itemBattery"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      column.columnType === "OPEN_ENDED"
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.summaryCard.columnDescription.OES.duplicateCheck"
                                        )
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _vm.datafileInputObject.enableDuplicateChecks
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "info" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.datafileInputObject.enableDuplicateChecks
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "light" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.label"
                                        )
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _vm.datafileInputObject.isEnableWrongTopicCheck
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "info" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.datafileInputObject.isEnableWrongTopicCheck
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "light" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      column.columnType === "RESPONDENT_ID"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 mt-3 p-3",
                              staticStyle: { border: "1px solid #e3e3e3" },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.respondentId"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-9" }, [
                                  _vm._v(
                                    " " + _vm._s(column.datasetColumnName) + " "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      column.columnType === "OPEN_ENDED"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 mt-3 p-3",
                              staticStyle: { border: "1px solid #e3e3e3" },
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.summaryCard.columnDescription.OES.dataPointIdetifer"
                                        )
                                      ) + ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-9" }, [
                                  _vm._v(
                                    " " + _vm._s(column.datasetColumnName) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "uploadProcessStep3.summaryCard.columnDescription.OES.questionTitle"
                                        )
                                      ) + ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-9" }, [
                                  _vm._v(
                                    " " + _vm._s(column.questionTitle) + " "
                                  ),
                                ]),
                              ]),
                              column.keywords.length > 0 &&
                              _vm.datafileInputObject.isEnableWrongTopicCheck
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "uploadProcessStep3.summaryCard.columnDescription.OES.keywords"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-9" },
                                      _vm._l(
                                        column.keywords,
                                        function (keyword, keywordIndex) {
                                          return _c(
                                            "span",
                                            { key: keywordIndex },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: { variant: "success" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(keyword.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              column.languagesToCheck &&
                              column.languagesToCheck.length > 0
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "uploadProcessStep3.summaryCard.columnDescription.OES.languages"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-9" },
                                      _vm._l(
                                        column.languagesToCheck,
                                        function (language, languageIndex) {
                                          return _c(
                                            "span",
                                            { key: languageIndex },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: { variant: "primary" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(language.code) +
                                                      " - " +
                                                      _vm._s(language.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      column.columnType === "OPEN_ENDED" &&
                      column.openEndedList.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              column.openEndedList,
                              function (oesDataPoint, oesDataPointIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: oesDataPointIndex,
                                    staticClass: "col-12 mt-3 p-3",
                                    staticStyle: {
                                      border: "1px solid #e3e3e3",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep3.summaryCard.columnDescription.OES.dataPointIdetifer"
                                              )
                                            ) + ": "
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              oesDataPoint.datasetColumnName
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep3.summaryCard.columnDescription.OES.questionTitle"
                                              )
                                            ) + ": "
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(oesDataPoint.questionTitle) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    oesDataPoint.keywords.length > 0 &&
                                    _vm.datafileInputObject
                                      .isEnableWrongTopicCheck
                                      ? _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-3" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "uploadProcessStep3.summaryCard.columnDescription.OES.keywords"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-9" },
                                            _vm._l(
                                              oesDataPoint.keywords,
                                              function (keyword, keywordIndex) {
                                                return _c(
                                                  "span",
                                                  { key: keywordIndex },
                                                  [
                                                    _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "mr-2 mb-1",
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                        attrs: {
                                                          variant: "success",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              keyword.text
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ])
                                      : _vm._e(),
                                    oesDataPoint.languagesToCheck &&
                                    oesDataPoint.languagesToCheck.length > 0
                                      ? _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-3" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "uploadProcessStep3.summaryCard.columnDescription.OES.languages"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-9" },
                                            _vm._l(
                                              column.languagesToCheck,
                                              function (
                                                language,
                                                languageIndex
                                              ) {
                                                return _c(
                                                  "span",
                                                  { key: languageIndex },
                                                  [
                                                    _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "mr-2 mb-1",
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                        attrs: {
                                                          variant: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              language.code
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              language.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      column.columnType === "TIMESTAMP"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 mt-3 p-3",
                              staticStyle: { border: "1px solid #e3e3e3" },
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.TS.mainTimeStamp"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _vm._v(
                                    " " + _vm._s(column.datasetColumnName) + " "
                                  ),
                                ]),
                              ]),
                              column.timeStampsList &&
                              column.timeStampsList.length > 0
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep3.summaryCard.columnDescription.TS.aditionalTimeStamps"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8" },
                                      _vm._l(
                                        column.timeStampsList,
                                        function (timeStamp, timeStampIndex) {
                                          return _c(
                                            "div",
                                            { key: timeStampIndex },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    timeStamp.datasetColumnName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              column.groupSelector.length > 0
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep3.summaryCard.columnDescription.TS.groupSelection"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8" },
                                      _vm._l(
                                        column.groupSelector,
                                        function (
                                          groupSelector,
                                          groupSelectorIndex
                                        ) {
                                          return _c(
                                            "span",
                                            {
                                              key: groupSelectorIndex,
                                              staticClass: "mr-2",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    groupSelector.datasetColumnName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      column.columnType === "PROJECTIVE_QUESTION"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 mt-3 p-3",
                              staticStyle: { border: "1px solid #e3e3e3" },
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.PQ.dataPointIdetifer"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _vm._v(
                                    " " + _vm._s(column.datasetColumnName) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.PQ.predictionColumns"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-8" },
                                  _vm._l(
                                    column.predictionsList,
                                    function (
                                      predictionColumn,
                                      predictionColumnIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: predictionColumnIndex,
                                          staticClass: "mr-2 mb-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                predictionColumn.datasetColumnName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.PQ.selectedQualityScore"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  column.qualityScores.PS
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "mr-2 mb-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { variant: "primary" },
                                            },
                                            [_vm._v(" PS ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.qualityScores.CS
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "mr-2 mb-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { variant: "primary" },
                                            },
                                            [_vm._v(" CS ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.qualityScores.SDS
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "mr-2 mb-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { variant: "primary" },
                                            },
                                            [_vm._v(" SDS ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.qualityScores.IS
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "mr-2 mb-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { variant: "primary" },
                                            },
                                            [_vm._v(" IS ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      column.columnType === "ITEMBATTERY"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 mt-3 p-3",
                              staticStyle: { border: "1px solid #e3e3e3" },
                            },
                            [
                              _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.IBS.mainItemBattary"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-12" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(column.datasetColumnName) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "bx bx-arrow-back bx-flip-horizontal ml-3 mr-3",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            column.itemBatteryEndRangeName
                                          ) +
                                          " "
                                      ),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v(
                                          " (" +
                                            _vm._s(
                                              column.itemBatteryColumnNames
                                                .length
                                            ) +
                                            ") "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12 mt-1" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated"
                                                )
                                              ) +
                                              ": "
                                          ),
                                        ]),
                                        _vm.datafileInputObject
                                          .enablePatternCheckMain
                                          ? _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: { variant: "info" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.datafileInputObject
                                          .enablePatternCheckMain
                                          ? _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: { variant: "light" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              column.itemBatteryRanges.length > 0
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep3.summaryCard.columnDescription.IBS.aditionalItemBattary"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8" },
                                      _vm._l(
                                        column.itemBatteryRanges,
                                        function (itemRange, itemRangeIndex) {
                                          return _c(
                                            "div",
                                            { key: itemRangeIndex },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row mb-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            itemRange.rangeStartName
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "bx bx-arrow-back bx-flip-horizontal ml-3 mr-3",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            itemRange.rangeEndName
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                itemRange
                                                                  .columnNames
                                                                  .length
                                                              ) +
                                                              ") "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-12 mt-1",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]),
                                                      _vm.datafileInputObject
                                                        .enablePatternCheckAdditional[
                                                        itemRangeIndex
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            [
                                                              _c(
                                                                "b-badge",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "12px",
                                                                  },
                                                                  attrs: {
                                                                    variant:
                                                                      "info",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.datafileInputObject
                                                        .enablePatternCheckAdditional[
                                                        itemRangeIndex
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            [
                                                              _c(
                                                                "b-badge",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "12px",
                                                                  },
                                                                  attrs: {
                                                                    variant:
                                                                      "light",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "fixed-bottom static-buttons",
          class: [_vm.isExpanded ? "expanded" : "collapsed"],
        },
        [
          _c("div", { staticClass: "card-body col-md-12 row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 text-left" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnBack()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-chevron-left" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("uploadProcessStep3.general.backButton")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 text-right" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      variant: "success",
                      disabled: _vm.isCreditsLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnNextStep()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("uploadProcessStep3.general.finishButton")
                        ) +
                        " "
                    ),
                    _c("i", { staticClass: "mdi mdi-check" }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.showUploadConfirmationModal === true
        ? _c("UploadDatafileConfirmationModal", {
            attrs: {
              loggedInUser: this.loggedInUser,
              visible: _vm.showUploadConfirmationModal,
              datafileInputObject: this.datafileInputObject,
              selectedColumns: this.selectedColumns,
              creditAmountReturn: this.creditAmountReturn,
              enableProgressBar: this.enableProgressBar,
            },
            on: {
              close: function ($event) {
                _vm.showUploadConfirmationModal = false
              },
            },
          })
        : _vm._e(),
      _vm.showNotEnoughCreditsModal === true
        ? _c("NotEnoughCreditsToUploadModal", {
            attrs: { visible: _vm.showNotEnoughCreditsModal },
            on: {
              close: function ($event) {
                _vm.showNotEnoughCreditsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }