var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-lg-12 mb-3 text-center" }, [
          _c("h5", [
            _vm._v(
              _vm._s(_vm.$t("uploadProcessStep3.uploadConfirmationPopup.title"))
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm.beforeUpload
                ? _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "uploadProcessStep3.uploadConfirmationPopup.description"
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.showProgressBar
                ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "uploadProcessStep3.uploadConfirmationPopup.calculating"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.showProgressBar
                ? _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-progress",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "font-size": "15px" },
                          attrs: {
                            max: 100,
                            "show-progress": "",
                            animated: "",
                            height: "30px",
                          },
                        },
                        [
                          _c("b-progress-bar", {
                            attrs: {
                              value: _vm.uploadProgress,
                              "show-value": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProgressBar
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mb-3" },
                    [
                      _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.uploadConfirmationPopup.bePatience.title"
                              )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.uploadConfirmationPopup.bePatience.text1"
                              )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.uploadConfirmationPopup.bePatience.text2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.uploadDatafileSuccess
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mb-3" },
                    [
                      _c("i", {
                        staticClass:
                          "bx bx-check-circle bx-tada text-success display-1",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.uploadDatafileSuccess
                ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "uploadProcessStep3.uploadConfirmationPopup.success.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.uploadDatafileSuccess
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center mb-3",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.uploadConfirmationPopup.success.text"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isUploadDatafileError
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mb-3" },
                    [
                      _c("i", {
                        staticClass:
                          "bx bx-x-circle bx-tada text-danger display-1",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.isUploadDatafileError
                ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "uploadProcessStep3.uploadConfirmationPopup.error.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.isUploadDatafileError
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mb-3" },
                    [
                      _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep3.uploadConfirmationPopup.error.text"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:support@redem.io?subject=Probleme beim Hochladen des Datenfiles",
                            },
                          },
                          [_vm._v("support@redem.io")]
                        ),
                        _vm._v(". "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger" },
                  model: {
                    value: _vm.isUploadDatafileError,
                    callback: function ($$v) {
                      _vm.isUploadDatafileError = $$v
                    },
                    expression: "isUploadDatafileError",
                  },
                },
                [_vm._v(_vm._s(_vm.uploadDatafileErrorMsg) + " ")]
              ),
            ],
            1
          ),
        ]),
        _vm.beforeUpload && _vm.loggedInUser.accessLevel === "SUPER_ADMIN"
          ? _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "mt-2",
                    attrs: { name: "check-button", switch: "" },
                    model: {
                      value: _vm.keepS3File,
                      callback: function ($$v) {
                        _vm.keepS3File = $$v
                      },
                      expression: "keepS3File",
                    },
                  },
                  [_vm._v(" Keep XLSX file in S3 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.beforeUpload && _vm.loggedInUser.accessLevel === "SUPER_ADMIN"
          ? _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "mt-2",
                    attrs: { name: "check-button", switch: "" },
                    model: {
                      value: _vm.skipFeedbackFile,
                      callback: function ($$v) {
                        _vm.skipFeedbackFile = $$v
                      },
                      expression: "skipFeedbackFile",
                    },
                  },
                  [_vm._v(" Skip Feedback File (Reading & Writing) ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "p-4 col-md-12 text-center" },
          [
            _vm.beforeUpload
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.uploadDataSet()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "uploadProcessStep3.uploadConfirmationPopup.confirmButton"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.beforeUpload || _vm.isUploadDatafileError
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        _vm.showModal = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "uploadProcessStep3.uploadConfirmationPopup.cancelButton"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.uploadDatafileSuccess
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDatasets()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "uploadProcessStep3.uploadConfirmationPopup.continueToResultButton"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showProgressBar
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.clickMakeUploadBackground()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "uploadProcessStep3.uploadConfirmationPopup.backToDashboard"
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }